// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// coreui v4 back-ported
@import "_coreui4";

// If you want to add something do it here
@import "custom";

// Dropzone styling
@import "~react-dropzone-uploader/dist/styles.css";