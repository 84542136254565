
@mixin avatar($width) {
  width: $width;
  height: $width;
  font-size: $width / 2.5;

  .avatar-status {
    width: $width / 3.75;
    height: $width / 3.75;
  }
}

@mixin ltr {
  @if $enable-ltr {
    html:not([dir="rtl"]) & {
      @content;
    }
  }
}

@mixin rtl {
  @if $enable-rtl {
    *[dir="rtl"] & {
      @content;
    }
  }
}

@function reflect($element) {
  @if type-of($element) == string {
    @if str-index($element, "left") {
      @return str-replace($element, "left", "right");
    }
    @if str-index($element, "right") {
      @return str-replace($element, "right", "left");
    }
  }

  @return $element;
}

@mixin ltr-rtl($property, $value, $property-rtl: null, $value-rtl: null, $important: null) {
  $property-reflected: reflect($property);
  $value-reflected: reflect($value);

  @if $enable-ltr and $enable-rtl {
    @include ltr() {
      #{$property}: $value $important;
    }
    @include rtl() {
      @if $value-rtl {
        #{$property-reflected}: $value-rtl $important;
      }
      @else {
        #{$property-reflected}: $value-reflected $important;
      }
    }
  }
  @else {
    @if $enable-rtl {
      @if $value-rtl {
        #{$property-reflected}: $value-rtl $important;
      }
      @else {
        #{$property-reflected}: $value-reflected $important;
      }
    }
    @else {
      #{$property}: $value $important;
    }
  }
}

// Avatars
// scss-docs-start avatar-variables
$avatar-width:  2rem !default;

$avatar-widths: (
  sm: 1.5rem,
  md: 2.5rem,
  lg: 3rem,
  xl: 4rem
) !default;

$avatar-transition: margin .15s !default;
// scss-docs-end avatar-variables

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  @include border-radius(50em);
  @include transition($avatar-transition);
  @include avatar($avatar-width);
}

.avatar-img {
  width: 100%;
  height: auto;
  @include border-radius(50em);
}

.avatar-status {
  position: absolute;
  @include ltr-rtl("right", 0);
  bottom: 0;
  display: block;
  border: 1px solid $white;
  @include border-radius(50em);
}

@each $width, $value in $avatar-widths {
  .avatar-#{$width} {
    @include avatar($value);
  }
}

.avatars-stack {
  display: flex;

  .avatar {
    @include ltr-rtl("margin-right", - ($avatar-width / 2.5));

    &:hover {
      @include ltr-rtl("margin-right", 0);
    }
  }

  @each $width, $value in $avatar-widths {
    .avatar-#{$width} {
      @include ltr-rtl("margin-right", - ($value / 2.5));
    }
  }
}