// Here you can add other styles

.c-sidebar-brand {
  background-color: white !important;
}

.c-login {
  background: url('../assets/background.jpg') no-repeat fixed;
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-title {
  line-height: 2.2rem;
}

img.avatar-img {
  height: 100%;
  width: 100%;
}

img.c-img-content-preview {
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

/* For disable jsonEditor line number and toolbar */
.ace_gutter, .jsoneditor-menu {
  display: none;
}

.jsoneditor-react-container {
  height: 400px;
}